<template>
  <div class="input-fl hide-pc">
    <h2 class="title1">{{$t('perfectData.perfectDataLeftTitle1')}}</h2>
    <p class="title2">{{$t('perfectData.perfectDataLeftTitle2')}}</p>
    <div class="step-box">
      <div class="step-list active">
        <img src="../assets/images/register/bz-icon2.png" alt="">
        <p class="p1">{{$t('perfectData.step2Title4')}}</p>
        <p class="p2">{{$t('perfectData.step2Title1')}}</p>
        <p class="p2">{{$t('perfectData.step2Title2')}}</p>
        <p class="p2">{{$t('perfectData.step2Title3')}}</p>
      </div>
      <div class="step-list">
        <img src="../assets/images/register/bz-icon3s.png" alt="">
        <p class="p1">{{$t('perfectData.step3Title1')}}</p>
        <p class="p2">{{$t('perfectData.step3Title2')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  data() {
    return {
    }
  },
  created() {
   
  },
  methods: {
    
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .input-fl{padding: 50px 0 50px 50px; width: 466px;height: 528px;background: url(../assets/images/register/bg1.png) no-repeat;background-size: 100% 100%;}
  .input-fl .title1{font-size: 24px;color: #000;font-weight: bold;}
  .input-fl .title2{font-size: 16px;margin-top: 15px;color: #333;}
  .step-box{margin-top: 80px;}
  .step-list{padding: 0 0 50px 30px; position: relative;border-left: 3px solid #e0e0e0;display: flex;flex-direction: column;justify-content: space-between;align-items: flex-start;}
  .step-list.active{border-left: 3px solid #ffd94c;}
  .step-list:last-child{border: none;}
  .step-list img{width: 27px;height: 27px;position: absolute;top: -2px;left: -14px;}
  .step-list:last-child img {position: absolute;top: -2px;left: -12px;} 
  .step-list .p1{font-size: 20px;color: #000;margin-bottom: 15px;margin-top: -10px;}
  .step-list .p2{font-size: 16px;color: #808080;margin-bottom: 10px;}
</style>
