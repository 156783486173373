<template>
  <div class="perfect-data-modal">
    <div class="mask" v-if="showModal" @click="perfectDataShow"></div>
    <div class="pop input-box" v-if="showModal">
      <div class="data-box">
        <img @click="perfectDataShow" class="close-btn" src="../assets/images/register/close.png" alt="">
        <perfect-data-left />
        
        <!-- 上传身份证照片 -->
        <div v-if="identityState == 1 && findUserInfo.bankAttestResult != 2" class="input-fr">
          <div class="input-fr-box">
            <h2>{{$t('perfectData.identityAuthentication')}}</h2>
            <!--<p class="title-p">{{$t('perfectData.identityAuthenTitle1')}}</p> -->
            <div class="from-box identity-rz-box">
              <country-input :countryList="countryList" :errShowList="errShowList" @country="selectCountry"/>             
              <div class="upload-image-content">
                <input @change="uploadFrontImage" name="frontImageFile" type="file" accept="image/png,image/gif,image/jpeg" id="uploadFront" style="display: none;"/>
                <label for="uploadFront">
                  <div class="bank-image">
                      <img v-if="idFrontImage" class="image-upload" :src="idFrontImage" />
                      <img src="@/assets/images/register/add-icon.png" class="image-add">
                      <div class="upload-image-tip"><p>{{$t('perfectData.identityAuthenTitle2')}}</p></div>
                  </div>
                </label>
                <p v-if="frontImgIdErr" class="err-text"><img src="../assets/images/register/dx-icon.png" alt="" /><span>{{frontImgIdErrContent}}</span></p>
              </div>
              <div class="upload-image-content">                
                <input @change="uploadReverseImage" name="reverseImageFile" type="file" accept="image/png,image/gif,image/jpeg" id="uploadReverse" style="display: none;"/>
                <label for="uploadReverse">
                  <div class="bank-image">
                      <img v-if="idReverseImage" class="image-upload" :src="idReverseImage" />
                      <img src="@/assets/images/register/add-icon.png" class="image-add">
                      <div class="upload-image-tip"><p>{{$t('perfectData.identityAuthenTitle3')}}</p></div>
                  </div>
                </label> 
                <p v-if="reverseImgIdErr" class="err-text"><img src="../assets/images/register/dx-icon.png" alt="" /><span>{{reverseImgIdErrContent}}</span></p> 
              </div>                   
              <input @click="confirmIdentityShow" class="input-btn" type="button" :value="$t('perfectData.nextStep')" />
            </div>
          </div> 
        </div>

        <!-- 上传身份证图片下一步的识别提示框 -->
        <div v-if="upLoadState" class="loading-box">
            Uploading...
        </div>

          <!-- 个人信息确认 -->
        <div v-if="identityState == 2" class="input-fr">
          <div class="input-fr-box">
            <h2>{{$t('perfectData.informationConfirmTitle1')}}</h2>
            <p class="title-p">{{$t('perfectData.informationConfirmTitle2')}}</p>
            <div class="from-box">                     
              <div class="from-box-bg">
                <div class="conter-box">
                  <div class="real-name">
                    <div class="real-name-top">
                      <p class="top-title">{{$t('perfectData.informationConfirmTitle3')}}</p>
                      <div @click="modifyShow" class="modify-box">
                        <img src="../assets/images/register/xg-icon.png" alt="">
                        <p class="modify-btn">{{$t('perfectData.modify')}}</p>
                      </div>
                    </div>  
                    <input class="input-text" :value="frontIdInfo.name" disabled="true"/>
                  </div>
                  <div class="identity-card">
                        <p>{{$t('perfectData.informationConfirmTitle4')}}</p>
                        <input class="input-text" :value="frontIdInfo.idcard" disabled="true"/>
                  </div>
                  <div class="ts-box">
                    <img src="../assets/images/register/ys-icon.png" alt="">
                    <p>{{$t('perfectData.informationConfirmTitle5')}}</p>
                  </div>
                </div>            
              </div>
              <div class="fxts-box qr-ts">
                <img src="../assets/images/register/xz-icon.png" alt="">
                <p>{{$t('perfectData.perfectDataTips')}}</p>
              </div>  
              <input @click="identityConfirm(dataTypeUrl)" class="input-btn" type="button" :value="$t('perfectData.nextStep')" />         
            </div>
           </div> 
          </div>

          <!-- 身份认证失败 -->
          <div v-if="identitySuccessful == 1" class="input-fr">
            <div class="input-fr-box">
              <img class="rz-icon" src="../assets/images/register/rzsb.png" alt="">
              <p class="p1">{{$t('perfectData.authenticationFail')}}</p>
              <p class="p3">{{identityMessage}}</p>
              <p class="p2">{{$t('perfectData.authenticationFailTitle2')}}</p>
              <p class="p2">{{$t('perfectData.authenticationFailTitle3')}}</p>
              <p class="p2">{{$t('perfectData.authenticationFailTitle4')}}</p>
              <div class="link-box">
                <button @click="ReturnModify" class="link-btn2">{{$t('perfectData.returnModify')}}</button>
                <a class="link-btn1" :href="serviceLink" target="_blank">{{$t('perfectData.onlineConsultation')}}</a>               
              </div>  
            </div>
          </div> 
          <!-- 身份认证状态 -->
          <div v-if="findUserInfo.bankAttestResult == 2" class="input-fr">
            <div class="input-fr-box">
              <img class="rz-icon" src="../assets/images/register/file-read.png" alt="">
              <p class="p1 shz">{{$t('accountInfor.underReview')}}</p>
              <div class="link-box">
                <a class="link-btn3" href="./deposit">{{$t('perfectData.immediateDeposit')}}</a>
                <a class="link-btn1" :href="serviceLink">{{$t('perfectData.onlineConsultation')}}</a>               
              </div>  
            </div>
          </div>
        </div> 
      </div>  

      <!-- 提示框组件 -->
    <Dialog ref="myConfirm"  :isShowConfirm="isShowConfirm" @userBehavior="userBehaviorFun" />   
  </div> 

    

  

</template>

<script>
import PerfectDataLeft from '@/components/PerfectDataLeft.vue';
import CountryInput from '@/components/CountryInput.vue';
import MobileInput from '@/components/MobileInput.vue';
import CodeInput from '@/components/CodeInput.vue';
import Dialog from '@/components/Dialog.vue';
import account from '@/api/account.js';
import { getCookie } from '@/util/cookie';
import {Config} from "@/util/const";

export default{
  props:["showModal","dataTypeUrl"],
  components: {
    PerfectDataLeft,
    MobileInput,
    CodeInput,
    CountryInput,
    Dialog
  },
  data() {
    return {
      isShowConfirm:false,
      identitySuccessful:0,
      identityState:1,
      serviceLink:'',
      upLoadState : false,
      findUserInfo:[],
      idFrontImage:'',
      idReverseImage:'',
      frontImgId:'',
      reverseImgId:'',
      frontImgIdErr:false,
      reverseImgIdErr:false,
      reverseImgIdErrContent:'',
      frontImgIdErrContent:'',
      frontIdInfo:[],
      countryList: {
        countrys: [],
        countryActive:'',
      },
      countryCurrent:[],
      errShowList: {
        errShow:false,
        errContent:'',
      },
      identityMessage:'',
    };
  },
  created() {
    account.findUserInfo().then((res)=>{
        if(res.data.success == 1){
              this.findUserInfo = res.data.value
          }else{
              console.log(res.data.message);
          }
      }).catch(err=>{
        console.log(err.message);
      })
     // 获取国家下拉列表数据
    account.getSupportCountry({"type":"all"}).then((res)=>{
     this.countryList.countrys = res.data.value
    //  this.countryList.countrys = res.data.value.filter(it => it.isEnable == 1);
    //  this.countryList.countryActive = res.data.value[0].countryId
     let countryCurrent = this.countryList.countrys.filter(it => it.countryId == this.countryList.countryActive);
     this.countryCurrent = countryCurrent;
     this.countryList.countryActive = countryCurrent[0].countryId;
    }).catch(err=>{
      console.log(err);
    })
    let lang1 = getCookie('LOCALE');
    if(lang1 == "zh_CN"){
          lang1 = "zh";
      }else if(lang1 == "zh_TW"){
          lang1 = "zh_TW"
      }else if(lang1 == "en_US"){
          lang1 = "en"
      }else if(lang1 == "id_ID"){
          lang1 = "id"
      }else if(lang1 == "th_TH"){
          lang1 = "th"
      }else if(lang1 == "hi_IN"){
          lang1 = "hi"
      }
    this.serviceLink = "https://chat10.live800.com/live800/chatClient/chatbox.jsp?companyID=1524200&configID=239203&jid=5772502639&s=1&lan="+lang1;
  },
  methods: { 

    // 获取选中的国家及国家区码
     selectCountry(val){
      let countryCurrent = this.countryList.countrys.filter(it => it.countryId == val);
      this.countryCurrent = countryCurrent;
      this.countryList.countryActive = countryCurrent[0].countryId;
      this.errShowList.errShow = false;
      this.reverseImgIdErr = false;
      console.info("countryCurrent",this.countryCurrent[0].countryId);
    },
    
    // 上传身份证正面照片
    uploadFrontImage(e){
      var self = this
      let file = e.target.files[0];
      console.log(file.name+"file");
      let param = new FormData(); // 创建form对象
      param.append('file', file, file.name) // 通过append向form对象添加数据
      account.uploadIdImg(param).then((res)=>{
          if(res.data.success == 1){
              this.idFrontImage = Config.images + res.data.value.originalFullName;
              this.frontImgId = res.data.value.id;
              this.frontImgIdErr = false;
              this.reverseImgIdErr = false;
          }else{
              console.log(res.data.message+"aaa");
          }
      }).catch(err=>{
        console.log(err.message+"bbb");
      })
    },
     // 上传身份证反面照片
    uploadReverseImage(e){
      var self = this
      let file = e.target.files[0]
      let param = new FormData(); // 创建form对象
      param.append('file', file, file.name) // 通过append向form对象添加数据
      account.uploadIdImg(param).then((res)=>{
          if(res.data.success == 1){
            this.idReverseImage = Config.images + res.data.value.originalFullName;
            this.reverseImgId = res.data.value.id;
            this.reverseImgIdErr = false;
          }else{
              console.log(res.data.message+"111");
          }
      }).catch(err=>{
        console.log(err.message+"222");
      })
    },
    // 上传完身份证，提交身份证进行下一步获取身份证信息
    confirmIdentityShow(){
      if(!this.countryList.countryActive){
        this.errShowList.errShow = true;
        this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt7'); 
        return;
      }else{
        this.errShowList.errShow = false;
      }
      if(!this.frontImgId){
        this.errShowList.errShow = false;
        this.frontImgIdErr = true;
        this.frontImgIdErrContent = this.$i18n.t('loginRegister.checkPrompt23');
        return;
      }
      if(!this.reverseImgId){
        this.frontImgIdErr = false;
        this.reverseImgIdErr = true;
        this.reverseImgIdErrContent = this.$i18n.t('loginRegister.checkPrompt24');
        return;
      }
      this.upLoadState = true;
      let data = {
        countryId: this.countryList.countryActive ,
        idFrontImg :this.frontImgId
      }
      account.getIDCardInfo(data).then((res)=>{
          if(res.data.success == 1){
            this.reverseImgIdErr = false;
            this.frontIdInfo = res.data.value;
            // this.identityState = 2;
            this.identityState = 0;
            this.upLoadState = false;
            this.identityConfirm();
          }else{
            this.upLoadState = false;
            this.reverseImgIdErr = true;
            this.reverseImgIdErrContent = res.data.message;
              console.log(res.data.message+"111");
          }
      }).catch(err=>{
        this.upLoadState = false;
        this.reverseImgIdErr = true;
        this.reverseImgIdErrContent = err.message;
        console.log(err.message+"222");
      }) 
    },
    // 返回身份证修改
     modifyShow(){
      this.identityState = 1;
      this.countryList.countryActive = '';
    },
    // 确认身份信息，进行实名认证
    identityConfirm(dataTypeUrl){
      const that = this;
      let data = {
        idFrontImg :that.frontImgId,
        idBehindImg :that.reverseImgId,
        realName :that.frontIdInfo.name,
        idNum :that.frontIdInfo.idcard,
        firstName:that.frontIdInfo.firstName,
        lastName:that.frontIdInfo.lastName,
        firstNameLocal:that.frontIdInfo.firstNameLocal,
        lastNameLocal:that.frontIdInfo.lastNameLocal,
        fullNameLocal:that.frontIdInfo.fullNameLocal,
        middleName:that.frontIdInfo.middleName,
        sex:that.frontIdInfo.sex,
      }
       account.realname(data).then((res)=>{
          if(res.data.success == 1){
            var _path = "";
            if(dataTypeUrl){
              _path = 'addBankCard'
            }else{
              _path = 'bankCardVerification'
            }
            that.$router.push({
              path: _path     
            });
          }else{
              this.identityState = 0 ;
              this.identityMessage = res.data.message;  
              this.identitySuccessful = 1;
          }
      }).catch(err=>{
        console.log(err.message);
      }) 
    },
    ReturnModify(){
      this.identityState = 1 
      this.identitySuccessful = 0;
      this.countryList.countryActive = '';
    },
    
    //完善资料组件传递关闭弹窗事件,弹出对应的提示框,并传值给提示框组件
    perfectDataShow(){
      const popTitle = this.$i18n.t('perfectData.popPrompt');
      const cancel = this.$i18n.t('perfectData.cancel');
      const confirm = this.$i18n.t('perfectData.continue');
      const title = this.$i18n.t('accountCenter.reminder');
      this.isShowConfirm = true
      this.$refs.myConfirm.show(popTitle, {
          type: 'confirm',
          cancelText:cancel,
          confirmText: confirm,
          titleText: title,
          data: '我是外界传进来的数据'
      })
    },

     // 提示框组件传递取消和确定事件,根据对应的事件类型跳转或关闭提示框
    userBehaviorFun(type){
      console.log(type);
      const that = this;
      if(type === "clickCancel"){
        that.$router.push("./");//去账户首页
        this.isShowConfirm = false    
      }else if(type === "clickConfirm"){
        // that.$router.push("./");
        this.isShowConfirm = false    
      }          
    },
    
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  /* 弹窗主题样式 */
  /* .perfect-data-modal{height:628px;margin-top: 70px;} */
  .mask {background-color: #000;opacity: 0.3;position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9}
  .pop {background-color: #fff;position: absolute;top: 75px;left: 50%;margin-left: -516px; z-index: 99;}
  .data-box{width: 100%;height: 100%;position: relative;display: flex;flex-direction: row;justify-content: space-between;align-items: center;}
  .close-btn {width: 22px;height: 22px; position: absolute;top: 30px;right: 30px;cursor: pointer;}
  .fxts-box{margin: 20px 0;display: flex;flex-direction: row;justify-content:flex-start;align-items: center;}
  .identity-rz-box .input-list{width: 385px;margin: auto;margin-bottom: 30px;}
  .from-box .upload-image-content{width: 385px;}
  .fxts-box img{width: 15px;height: 15px;margin-right: 5px;}
  .fxts-box p{font-size: 14px;color: #c1c1c1;}
  .loading-box{font-size: 16px;color:#fff; width: 200px;height: 100px;line-height: 100px;border-radius: 6px;margin-top: -50px;margin-left: -100px; text-align: center; background: #000;opacity: 0.5;position: fixed;top: 50%;left: 50%;z-index: 1}

@media screen and (max-width: 768px) {
  /* .perfect-data-modal{height:100%;margin-top: 3rem;} */
  .mask {background-color: #000;opacity: 0.3;position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9}
  .pop {position: fixed;top: 3rem;left: 50%;margin-left: -45%; height: 75%; z-index: 99;background-color: #fff;}
  .data-box{width: 100%;height: 100%;position: relative;display: flex;flex-direction: row;justify-content: space-between;align-items: center;}
  .close-btn {width: 1.2rem;height: 1.2rem; position: absolute;top: 1rem;right: 1rem;cursor: pointer;}
  .fxts-box{margin-bottom: 1rem;margin-top: 2rem; display: flex;flex-direction: row;justify-content:flex-start;align-items: center;}
  .identity-rz-box .input-list{width: 100%;margin-bottom: 1.4rem;}
  .from-box .upload-image-content{width: 100%;}
  .from-box .upload-image-content .bank-image{height: 5.5rem;}
  .fxts-box img{width: 0.7rem;height: 0.7rem;margin-right: 0.3rem;}
  .fxts-box p{font-size: 0.6rem;color: #c1c1c1;}
  .pop .input-btn{margin-bottom: 0;}
  .pop .qr-ts{margin-top: 1rem;}
  .loading-box{font-size: 0.8rem;color:#fff;width: 60%;height: 3rem;line-height: 3rem;border-radius: 0.2rem;margin-top: -1.5rem;margin-left: -30%; text-align: center; background: #000;opacity: 0.5;position: fixed;top: 50%;left: 50%;z-index: 1}
  }
  
</style>
